
<div class="w-[1280px] m-auto h-screen p-5 printarea">
  <div mat-dialog-content class="flex flex-col space-y-2">
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-col space-y-2">
        <div class="text-center"> TRƯỜNG CAO ĐẲNG NGHỀ</div>
        <div class="text-center"> THÀNH PHỐ HỒ CHÍ MINH</div>
        <div class="text-center font-bold"> KHOA: ĐIỆN - ĐIỆN LẠNH</div>
      </div>

      <div class="flex flex-col space-y-2">
        <div class="text-center font-bold">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
        <div class="text-center font-bold">Độc lập - Tự do - Hạnh phúc</div>
        <div class="text-center">
          <span class="text-center p-2 font-bold border-2">Mẫu 0 </span>
        </div>

      </div>
    </div>
    <br />
    <div class="text-center font-bold text-xl uppercase">TỔNG HỢP THIẾT BỊ KHOA ĐIỆN - ĐIỆN LẠNH</div>
     <div class="text-center font-bold text-xl">{{Baocao?.Title}}</div>
    <table class="w-full table-auto border-collapse border border-gray-400">
      <tr class="font-bold">
        <td rowspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">STT</td>
        <td rowspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Tên TSCD</td>
        <td rowspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Mã số TSCĐ</td>
        <td rowspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Năm Sử Dụng</td>
        <td colspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Theo sổ kế toán</td>
        <td colspan="3" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Theo Kiểm Kê</td>
        <td colspan="3" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Chênh Lệch</td>
        <td rowspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Ghi Chú</td>
        <td rowspan="2" class="text-xs text-center whitespace-nowrap p-2 border border-gray-400">Mã Code</td>
      </tr>
      <tr class="font-bold">
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Số Lượng</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Giá Trị Còn Lại</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Số Lượng</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Nguyên giá</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Giá Trị Còn Lại</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Số Lượng</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Nguyên giá</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">Giá Trị Còn Lại</td>
      </tr>
      @for (item of DataMau; track $index) {
      <tr>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{$index+1}}</td>
        <td class="text-xs whitespace-wrap p-2 border border-gray-400 max-w-40">{{item.TenTSCD}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.MasoTSCD}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.NamSD}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.TheoSoSL}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.TheoSoConlai}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.KiemkeSL}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.KiemkeNguyengia}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.KiemkeConlai}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.ChenhlechSL}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.ChenhlechNguyengia}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.ChenhlechConlai}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.Ghichu}}</td>
        <td class="text-xs whitespace-nowrap p-2 border border-gray-400">{{item.MaCode}}</td>
      </tr>
      }
    </table>
    <div class="flex flex-row justify-between items-center px-8">
      <div>
        <div class="text-center"></div>
        <div class="text-center font-bold"> TRƯỞNG KHOA ĐIỆN LẠNH </div>
        <br />
        <br />
        <div class="text-center"> PHẠM VĂN TRỌNG</div>
      </div>

      <div>
        <div class="text-center font-bold">Ngày tháng năm </div>
        <div class="text-center">Nhân viên thực hiện</div>
        <br />
        <br />
        <div class="text-center">Dương Văn Tuấn</div>
      </div>
    </div>
  </div>
</div>

