<div class="flex w-screen h-screen justify-center items-center bg-blue-gray-100">
    <div class="container lg:max-w-lg lg:m-auto rounded-lg border p-8 flex flex-col space-y-6 bg-white">
      <div class="flex flex-col space-y-2">
        <img src="/assets/img/logo-vuong.png" class="p-3 mx-auto">
        <span class="text-center font-bold">TRƯỜNG CAO ĐẲNG NGHỀ TP.HỒ CHÍ MINH</span>
      </div>
      <div class="flex flex-col space-y-4 lg:w-2/3 w-full mx-auto">
        <mat-form-field subscriptSizing="dynamic"  appearance="outline" class="w-full">
          <mat-label>Họ Và Tên</mat-label>
          <input [(ngModel)]="User.Hoten" [ngModelOptions]="{standalone: true}" type="text" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Họ Và Tên'" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic"  appearance="outline" class="w-full">
          <mat-label>Số Điện Thoại</mat-label>
          <input [(ngModel)]="User.SDT" [ngModelOptions]="{standalone: true}" type="text" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Số Điện Thoại'" />
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic"  appearance="outline" class="w-full">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="User.email" [ngModelOptions]="{standalone: true}" type="email" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Email'" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic"  appearance="outline" class="w-full">
          <mat-label>Mật Khẩu</mat-label>
          <input [(ngModel)]="User.password" [ngModelOptions]="{standalone: true}" type="password" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Mật Khẩu'" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic"  appearance="outline" class="w-full">
          <mat-label>Xác Nhận Mật Khẩu</mat-label>
          <input [(ngModel)]="User.confirmpassword" [ngModelOptions]="{standalone: true}" type="password" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Xác Nhận Mật Khẩu'" />
        </mat-form-field>
        <div class="flex justify-center">
          <!-- <button mat-flat-button color="primary">Đăng Nhập</button> -->
          <button (click)="Dangky(User)" (keyup.enter)="Dangky(User)" mat-flat-button color="primary">Đăng Ký</button>
        </div>
        <div class="flex justify-center space-x-2">
          <span>Đã Có Tài Khoản ?</span>
          <a class="cursor-pointer font-bold text-blue-500" href="dangnhap">Đăng Nhập</a>
        </div>
  
      </div>
    </div>
  </div>