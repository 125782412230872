@if (idMau==0) {
  <app-baninmau0 [idBaocao]="idBaocao"></app-baninmau0>
}
@if (idMau==1) {
  <app-baninmau1 [idBaocao]="idBaocao"></app-baninmau1>
}
@else if (idMau==2) {
  <app-baninmau2 [idBaocao]="idBaocao"></app-baninmau2>
}
@else if (idMau==3) {
  <app-baninmau3 [idBaocao]="idBaocao"></app-baninmau3>
}
